import React, { useState, useEffect } from "react";

import TopBar from "../components/devicespage/TopBar";
import SearchBar from "../components/devicespage/SearchBar";
import CategoriesCard from "../components/devicespage/CategoriesCard";
import DevicesPagination from "../components/devicespage/DevicesPagination";

// import devices from "../data/devices_info.json"; // Import the dummy data

import { ApiAddress } from "../data/ApiAddress";

import ReactGA from "react-ga";

const Devices = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchCategory, setSearchCategory] = useState([]); // Now an array
  const [sortOption, setSortOption] = useState(null); // e.g., "Likes", "Files", etc.
  const [showdevices, setShowDevices] = useState([]); // Holds all the devices
  const [filteredDevices, setFilteredDevices] = useState([]); // Holds filtered devices based on searchTerm

  useEffect(() => {
    //non interaction
    ReactGA.pageview(window.location.pathname);
  }, []);

  // Fetch devices from the backend when the component is mounted
  useEffect(() => {
    fetch(ApiAddress + "device_list/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setShowDevices(data);
        setFilteredDevices(data); // Initially, show all devices
      })
      .catch((error) => console.error("Error fetching devices:", error));
  }, []);

  // Filter devices whenever searchTerm or searchCategory changes
  useEffect(() => {
    // Filtering logic
    const filterAndSortDevices = () => {
      let result = [...showdevices];
      if (searchTerm) {
        result = result.filter(
          (device) =>
            device.device &&
            device.device.toLowerCase().includes(searchTerm.toLowerCase())
        );
      }

      if (searchCategory.length > 0) {
        result = result.filter((device) =>
          searchCategory.includes(device.category)
        );
      }

      if (sortOption === "Likes") {
        result = result.sort((a, b) => b.likes - a.likes);
      } else if (sortOption === "Files") {
        result = result.sort((a, b) => b.files - a.files);
      } else if (sortOption === "Posts") {
        result = result.sort((a, b) => b.posts - a.posts);
      }

      return result;
    };

    const sortedAndFiltered = filterAndSortDevices();
    setFilteredDevices(sortedAndFiltered);
  }, [searchTerm, searchCategory, showdevices, sortOption]);

  return (
    // <div className="bg-dark-primary text-light-primary-text">Devices</div>

    <div className="flex flex-col w-full h-screen overflow-auto bg-dark-primary text-white">
      <TopBar />
      <div className="w-4/5 mx-auto flex flex-col border border-0">
        {" "}
        {/* Added mx-auto for centering */}
        <SearchBar
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          sortOption={sortOption}
          setSortOption={setSortOption}
        />
        <CategoriesCard
          searchCategory={searchCategory}
          setSearchCategory={setSearchCategory}
        />
      </div>

      <div className="w-4/5 mx-auto mt-5 flex flex-col border border-0">
        <DevicesPagination devices={filteredDevices} />
      </div>
    </div>
  );
};

export default Devices;
