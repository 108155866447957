import React from "react";
import TopBar from "../components/devicespage/TopBar";
import infocard_1 from "../assets/InfoCard_1.svg";
import infocard_2 from "../assets/InfoCard_2.svg";
import infocard_3 from "../assets/InfoCard_3.svg";
import infocard_4 from "../assets/InfoCard_4.svg";
import CardComponent from "../components/information/CardComponent";
import logoUrl from "../assets/logo_new.svg";
import { Link } from "react-router-dom";
// import Login from "../components/auth/Login";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const info_card_contents = [
  {
    title: "Unified Repository",
    info: "A  platform for all essential hardware-specific information, making it easier for engineers to find and manage resources.",
    image: infocard_1,
  },
  {
    title: "Community Driven",
    info: "Users share their learnings and repositories of codebases, building a community where knowledge is constantly updated, expanded, and shared.",
    image: infocard_2,
  },
  {
    title: "AI-Enhanced Support",
    info: "The integration of AI to assist with device-specific knowledge base and programming tasks, making complex tasks easier and more efficient.",
    image: infocard_3,
  },
  {
    title: "Enhanced Collaboration",
    info: "Teams can seamlessly collaborate on hardware projects, ensuring everyone is on the same page and improving productivity.",
    image: infocard_4,
  },
];

const MainPage = () => {
  return (
    <div className="flex flex-col w-full h-screen overflow-auto bg-dark-primary text-white">
      <TopBar />
      <div className="w-full lg:w-4/5 mx-auto flex flex-col border border-0 mb-10">
        {/* Grid Layout */}

        <div className="flex flex-col w-full justify-center items-center p-4">
          <img
            src={logoUrl}
            alt="Logo white"
            className="w-10 h-10 object-cover  mt-10"
          />
          <h1 className="text-center font-bold text-xl mt-8 mb-8">
            A platform for the engineering community to collaborate on hardware
            projects
          </h1>

          <div className="flex justify-center mb-4 ">
            <Link to="/devices">
              <button className="flex justify-center text-sm items-center gap-2 px-3 py-2 text-white rounded bg-gradient-to-br from-[#54E1AE] to-[#15BE81] hover:from-[#4ad5a0] hover:to-[#12a973]">
                Let's start <FontAwesomeIcon icon={faArrowRight} />
              </button>
            </Link>
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mt-4 m-2">
          {info_card_contents.map((card, idx) => (
            <CardComponent key={idx} {...card} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default MainPage;
