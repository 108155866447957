import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import DocContext from "./DocContext";
import ReactMarkdown from "react-markdown";
import Editor from "@monaco-editor/react";
import { ApiAddress } from "../../data/ApiAddress";

const SUPPORTED_CODE_EXTENSIONS = [
  "js",
  "jsx",
  "ts",
  "tsx",
  "css",
  "html",
  "py",
  "java",
  "c",
  "h",
  "cpp",
  "go",
  "rust",
  "php",
  "ino",
  "txt",
  "yml",
  "yaml",
  "json",
  "rst",
  "rs",
];

const DocView = ({ userName, deviceName, fileName }) => {
  const [pageNumber, setPageNumber] = useState(1);

  const location = useLocation();
  const { docUrl: contextDocUrl } = React.useContext(DocContext);
  const fileExtension = fileName.split(".").pop();

  const [content, setContent] = useState("");
  const [fetchedDocUrl, setFetchedDocUrl] = useState(null);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get("page");

    if (page) {
      setPageNumber(page);
    }
  }, [location.search]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    // const filePath = params.get("filePath");
    const encodedFilePath = params.get("filePath");

    const decodedFilePath = atob(encodedFilePath); // Base64 decoding

    if (decodedFilePath && !fetchedDocUrl) {
      // Avoid re-fetch if we already have the doc
      fetch(ApiAddress + "get_file/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          //   filePath: filePath,
          filePath: decodedFilePath,
        }),
      })
        .then((response) => response.blob())
        .then((blob) => {
          const docUrl = URL.createObjectURL(blob);
          setFetchedDocUrl(docUrl);
        })
        .catch((error) =>
          console.error("Error fetching file in new tab:", error)
        );
    }
  }, [location.search]);

  useEffect(() => {
    if (
      ["rst", "md"].includes(fileExtension) ||
      SUPPORTED_CODE_EXTENSIONS.includes(fileExtension)
    ) {
      const urlToFetch = fetchedDocUrl || contextDocUrl; // Prefer fetched URL, if available
      fetch(urlToFetch)
        .then((response) => response.text())
        .then(setContent);
    }
  }, [fileExtension, fetchedDocUrl, contextDocUrl]);

  const docUrl = fetchedDocUrl || contextDocUrl; // Use fetched URL if available, else fallback to context

  let renderContent;

  if (!docUrl) {
    renderContent = (
      <div className="border border-dark rounded p-2">
        No documents loaded to display
      </div>
    );
  } else if (["pdf", "jpg", "jpeg", "png", "gif"].includes(fileExtension)) {
    renderContent = (
      <iframe
        src={`${docUrl}#page=${pageNumber}`} // append the page number to the URL
        title="Document Viewer"
        className="w-full h-[100vh] mt-4"
      />
    );
  } else if (["rst", "md"].includes(fileExtension)) {
    renderContent = (
      <ReactMarkdown
        children={content}
        className="border border-dark rounded p-2"
      />
    );
  } else if (SUPPORTED_CODE_EXTENSIONS.includes(fileExtension)) {
    renderContent = (
      <Editor
        height="100vh"
        language={fileExtension}
        theme="vs-dark"
        value={content}
        options={{ readOnly: true }}
      />
    );
  } else {
    renderContent = (
      <div className="border border-dark rounded p-2">
        Unsupported file type. Please download the file to view it in your local
        machine.
        <a
          href={docUrl}
          download={fileName}
          className="block w-24 mt-2 bg-emerald-500 text-white py-1 px-4 rounded hover:bg-emerald-600"
        >
          Download
        </a>
      </div>
    );
  }

  return (
    <div className="text-light-primary rounded-md text-sm mt-4">
      <h1 className="text-md font-bold text-emerald-500 mb-2">{fileName}</h1>
      {renderContent}
    </div>
  );
};

export default DocView;
