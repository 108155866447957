import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSort,
  faPlus,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const SearchBar = ({
  searchTerm,
  setSearchTerm,
  sortOption,
  setSortOption,
}) => {
  //   const [searchTerm, setSearchTerm] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      // This is where you can handle the search logic if needed.
      // But since we're already updating the searchTerm state on every change,
      // this can be left empty.
    }
  };

  return (
    <div className="flex items-center justify-left p-2 mt-10 border border-0">
      {/* Label */}
      <div className="text-dark-secondary text-md font-semibold">Devices</div>

      {/* Search Bar */}
      <div className="relative flex-grow mx-4">
        {/* <input
          type="text"
          placeholder="Search for existing devices ..."
          className="w-full px-4 py-2 bg-dark-primary border border-dark rounded-lg text-white placeholder-dark focus:outline-none focus:border-emerald-600"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        /> */}
        <input
          type="text"
          placeholder="Search for existing devices ..."
          className="w-full px-4 py-2 bg-dark-primary border border-dark rounded-lg text-white placeholder-dark focus:outline-none focus:border-emerald-600"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        {searchTerm && (
          <div
            className="absolute top-1/2 transform -translate-y-1/2 right-3 cursor-pointer"
            onClick={() => setSearchTerm("")}
          >
            <FontAwesomeIcon icon={faTimesCircle} />
          </div>
        )}
      </div>

      {/* Action Buttons */}
      <div className="flex gap-3">
        {/* dropdown button for sorting */}
        <div className="relative">
          <button
            onClick={toggleDropdown}
            className="text-sm flex items-center gap-2 px-3 py-2 text-white rounded bg-gradient-to-br from-[#696969] to-[#545F71] hover:from-[#5e5e5e] hover:to-[#4c5265]"
          >
            <FontAwesomeIcon icon={faSort} />
            {sortOption ? `Sort by: ${sortOption}` : "Sort by"}
          </button>
          {showDropdown && (
            <div className="absolute top-full left-0 mt-2 w-48 rounded shadow-lg bg-dark-tertiary border border-dark">
              <div
                className="py-0"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="options-menu"
              >
                {["Likes", "Files", "Posts"].map((option) => (
                  <button
                    key={option}
                    className="w-full text-left text-dark-secondary px-4 py-2 text-md text-light-primary hover:bg-dark-secondary"
                    role="menuitem"
                    onClick={() => {
                      setSortOption(option);
                      toggleDropdown();
                    }}
                  >
                    {option}
                  </button>
                ))}
              </div>
            </div>
          )}
        </div>
        <Link to="/newdevice">
          <button className="text-sm flex items-center gap-2 px-3 py-2 text-white rounded bg-gradient-to-br from-[#54E1AE] to-[#15BE81] hover:from-[#4ad5a0] hover:to-[#12a973]">
            <FontAwesomeIcon icon={faPlus} />
            Create new
          </button>
        </Link>
      </div>
    </div>
  );
};

export default SearchBar;
