import React, { useState } from "react";
import GetIcon from "./GetIcon";
// import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faThumbsUp,
  faComment,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

import defaultImage from "../../assets/default_image.jpeg";
import { useNavigate } from "react-router-dom";
import { ApiAddress } from "../../data/ApiAddress";

const DeviceCard = ({ device }) => {
  const [hoveredCard, setHoveredCard] = useState(null); // State for hovered device card
  const navigate = useNavigate();
  const userName = localStorage.getItem("username");

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const handleTrashClick = (event, deviceInfo) => {
    event.stopPropagation();
    setShowConfirmDialog(true);
  };

  const confirmDelete = (deviceInfo) => {
    // console.log(deviceInfo);
    const [userNameInfo, deviceNameInfo] = deviceInfo.split("/");
    if (userName === userNameInfo) {
      // API call to delete the device
      fetch(ApiAddress + "delete_device/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          userName: localStorage.getItem("username"),
          deviceName: deviceNameInfo,
        }),
      })
        .then((response) => {
          if (!response.ok && response.status === 401) {
            // Token is probably expired. Remove local data and redirect to login.
            localStorage.removeItem("username");
            localStorage.removeItem("token");
            // Assuming you're using React Router
            window.location.href = "/login"; // Redirect to login page
            throw new Error("Token has expired. Please login again.");
          }
          return response.json();
        })
        .then((data) => {
          // console.log(data);
          // On success, re render the devices
          if (!data.error) {
            window.location.reload();
          }
        })
        .catch((error) => console.error("Error fetching device:", error));
    }
  };

  const handleCardClick = () => {
    navigate(`/devices/${device.device}`);
  };

  return (
    <>
      {showConfirmDialog && (
        <div className="fixed inset-0 z-100 flex items-center justify-center p-4 sm:p-0">
          {/* Backdrop */}
          <div className="absolute inset-0 bg-black opacity-80 backdrop-blur-md"></div>

          {/* Dialog */}
          <div className="relative bg-dark-primary border border-dark max-w-sm w-full p-4 rounded shadow-lg z-20">
            <p className="text-light-primary pb-2 font-semibold">
              Are you sure you want to delete {device.device}?
            </p>
            <p className="text-sm text-dark-secondary pb-2 font-light">
              This action will permanently remove this device.
            </p>
            <div className="flex justify-end mt-2">
              <button
                className="text-sm flex items-center gap-2 mr-4 px-3 py-2 text-light-primary rounded bg-gradient-to-br from-[#696969] to-[#545F71] hover:from-[#5e5e5e] hover:to-[#4c5265]"
                onClick={() => setShowConfirmDialog(false)}
              >
                Cancel
              </button>
              <button
                className="text-sm flex items-center gap-2 px-3 py-2 text-light-primary rounded bg-gradient-to-br from-[#54E1AE] to-[#15BE81] hover:from-[#4ad5a0] hover:to-[#12a973]"
                onClick={() => {
                  confirmDelete(device.device); // Function that contains the delete logic
                  setShowConfirmDialog(false);
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}

      <div
        onMouseEnter={() => {
          setHoveredCard(true);
        }}
        onMouseLeave={() => setHoveredCard(null)}
        onClick={handleCardClick}
        className="rounded-lg border border-dark bg-dark-github p-4 m-2 flex-shrink-0 hover:shadow-[rgba(16,185,129,0.8)_0px_0px_5px]"
      >
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-[5fr,1fr]">
          {/* Left Column - 80% */}
          <div>
            <div className="text-light-primary font-semibold">
              {device.device}
            </div>
            <div className="flex flex-wrap text-sm text-dark-secondary mt-4 font-light break-all">
              {/* Category and Icon */}
              <div className="flex items-center mr-4">
                <img
                  src={GetIcon(device.icon)}
                  alt={device.category}
                  className="h-4 w-4 mr-2"
                />
                {device.category}
              </div>

              {/* Files */}
              <div className="flex items-center mr-4">
                <span>{device.files} files</span>
              </div>

              {/* Likes */}
              <div className="flex items-center mr-4">
                <FontAwesomeIcon icon={faThumbsUp} className="h-4 w-4 mr-2" />
                {device.likes}
              </div>

              {/* Posts */}
              <div className="flex items-center">
                <FontAwesomeIcon icon={faComment} className="h-4 w-4 mr-2" />
                {device.posts}
              </div>
            </div>
          </div>

          {/* Right Column - 20% */}
          <div className="flex justify-end items-end">
            {" "}
            {/* Added items-end */}
            {hoveredCard && device.device.includes(userName) ? (
              <FontAwesomeIcon
                icon={faTrash}
                className="cursor-pointer text-dark-secondary pr-4 hover:text-emerald-500"
                onClick={(event) => handleTrashClick(event, device.device)} // Passing the event
              />
            ) : null}
            <div className="w-16 h-16 overflow-hidden rounded">
              <img
                src={device.image ? device.image : defaultImage}
                alt={`${device.device} icon`}
                className="w-full h-full object-cover"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeviceCard;
