import React from "react";
import DeviceCard from "./DeviceCard";
// import devices from "../data/devices_info.json"; // Import the data
import usePagination from "./usePagination";

const DevicesPagination = ({ devices }) => {
  const { next, prev, currentData, currentPage, maxPage } = usePagination(
    devices,
    8
  );

  return (
    <div className="flex flex-col items-center w-full border border-0">
      {" "}
      {/* Adjusted this line */}
      {/* Setting up the grid here */}
      <div className="grid grid-cols-2 gap-4 w-full">
        {" "}
        {/* Adjusted this line */}
        {currentData().map((device, index) => (
          <DeviceCard key={index} device={device} />
        ))}
      </div>
      <div className="mt-5 text-sm text-emerald-500">
        <button onClick={prev} disabled={currentPage === 1}>
          Prev
        </button>
        <span className="mx-4 text-sm text-emerald-500">
          {currentPage} of {maxPage}
        </span>
        <button onClick={next} disabled={currentPage === maxPage}>
          Next
        </button>
      </div>
    </div>
  );
};

export default DevicesPagination;
