import React, { useEffect, useState } from "react";
// import categoriesInfo from "../data/categories_info.json";
import { ApiAddress } from "../../data/ApiAddress";

import GetIcon from "./GetIcon";

const CategoriesCard = ({ searchCategory, setSearchCategory }) => {
  const [categoriesInfo, setCategoriesInfo] = useState([]);

  // Fetch devices from the backend when the component is mounted
  useEffect(() => {
    fetch(ApiAddress + "category_list/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        setCategoriesInfo(data);
      })
      .catch((error) => console.error("Error fetching devices:", error));
  }, []);

  // Function to handle the category button click
  const handleCategoryClick = (categoryName) => {
    if (searchCategory.includes(categoryName)) {
      // If already selected, remove from array
      const updatedCategories = searchCategory.filter(
        (cat) => cat !== categoryName
      );
      setSearchCategory(updatedCategories);
    } else {
      // If not selected, add to array
      setSearchCategory([...searchCategory, categoryName]);
    }
  };

  return (
    <div className="flex flex-wrap items-center justify-start p-2">
      {/* Label */}
      <div className="text-emerald-500 text-md sm:text-sm font-semibold mr-4">
        Categories
      </div>

      {/* Categories List */}
      <div className="flex flex-wrap items-center gap-2 text-sm">
        {categoriesInfo.map((category, index) => (
          <button
            key={index}
            onClick={() => handleCategoryClick(category.category)}
            className={`flex items-center gap-2 px-3 sm:px-2 py-1 sm:py-0.5 rounded-full border border-dark hover:bg-dark-secondary ${
              searchCategory.includes(category.category)
                ? "bg-dark-secondary"
                : ""
            }`}
          >
            <div className={`p-1 rounded`}>
              <img
                src={GetIcon(category.icon)}
                alt={category.category}
                className="h-4 w-4"
              />
            </div>
            <p className="text-dark-secondary">{category.category}</p>
          </button>
        ))}
      </div>
    </div>
  );
};

export default CategoriesCard;
