import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import logoUrl from "../../assets/Full_logo_white.svg";
import { useNavigate } from "react-router-dom";
import { ApiAddress } from "../../data/ApiAddress";
import DocContext from "../docviewer/DocContext";

// Import FontAwesome icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faMicrochip, faGrip } from "@fortawesome/free-solid-svg-icons";

const TopBar = () => {
  const navigate = useNavigate(); // Add this line

  const location = useLocation();

  // Check for username in local storage
  const username = localStorage.getItem("username");

  const { form, setForm } = useContext(DocContext);

  const isActive = (path) => {
    return location.pathname.includes(path);
  };

  const handleSignup = () => {
    setForm("signup");
    navigate("/login");
  };

  const handleLogin = () => {
    setForm("login");
    navigate("/login");
  };

  //  Logout makes API request to the backend and fetches access token
  const handleLogout = async () => {
    // console.log("Handle logout");

    // Make a fetch request to your backend's logout endpoint
    const response = await fetch(ApiAddress + "logout/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`, // send the token for validation
      },
    });

    if (response.ok) {
      // Remove the token from the local storage
      localStorage.removeItem("token");
      localStorage.removeItem("username");
      // Redirect to login or any other page as per your requirement
      navigate("/devices");
    } else {
      navigate("/login");
      // console.error("Failed to log out.");
      localStorage.removeItem("token");
      localStorage.removeItem("username");
    }
  };

  return (
    <div className="w-screen flex flex-shrink-0 justify-between items-center h-auto sm:h-[3.6rem] px-4 sm:px-8 bg-dark-primary text-light-primary border-b border-dark">
      {/* Logo */}
      <div>
        <Link to="/">
          <img
            alt="Logo"
            src={logoUrl}
            className="h-[1.5rem] sm:h-[1.8rem] mr-4"
          />
        </Link>
      </div>

      {/* Tabs */}
      <div className="flex flex-wrap space-x-4">
        <Link
          to="/devices"
          className={`flex items-center gap-1 sm:gap-2 pt-3 sm:pt-4 pb-3 sm:pb-4 pr-2 sm:pr-3 pl-1 sm:pl-2 border-b-2 ${
            isActive("/devices") ? "border-emerald-500" : "border-transparent"
          } hover:border-emerald-500 text-xs sm:text-base`}
        >
          <FontAwesomeIcon icon={faMicrochip} size="xs" />
          <span>Devices</span>
        </Link>
        <Link
          to="/projects"
          className={`flex items-center gap-1 sm:gap-2 pt-3 sm:pt-4 pb-3 sm:pb-4 pr-2 sm:pr-3 pl-1 sm:pl-2 border-b-2 ${
            isActive("/projects") ? "border-emerald-500" : "border-transparent"
          } hover:border-emerald-500 text-xs sm:text-base`}
        >
          <FontAwesomeIcon icon={faGrip} size="xs" />
          <span>Projects</span>
        </Link>
      </div>

      {/* Login Button */}
      <div className="flex items-center gap-2">
        {username ? (
          <React.Fragment>
            <FontAwesomeIcon icon={faUser} className="hidden sm:block" />
            <span className="text-xs sm:text-base">{username}</span>
            <button
              onClick={handleLogout}
              className="text-xs sm:text-base bg-emerald-500 hover:bg-emerald-400 text-light-primary px-3 py-1 rounded-full bg-gradient-to-br from-[#696969] to-[#545F71] hover:from-[#4ad5a0] hover:to-[#12a973]"
            >
              Logout
            </button>
          </React.Fragment>
        ) : (
          <>
            <button
              onClick={handleSignup}
              className="text-xs sm:text-base hover:text-emerald-500"
            >
              Sign up
            </button>

            <button
              onClick={handleLogin}
              className="text-xs sm:text-base bg-emerald-500 hover:bg-emerald-400 text-light-primary px-3 py-1 rounded-full bg-gradient-to-br from-[#54E1AE] to-[#15BE81] hover:from-[#4ad5a0] hover:to-[#12a973]"
            >
              Login
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default TopBar;
