import React, { useState } from "react";
import TopBar from "../components/devicespage/TopBar";
import CardComponent from "../components/information/CardComponent";
// import Login from "../components/auth/Login";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { ApiAddress } from "../data/ApiAddress";

const info_card_contents = [
  {
    title: "Create a private repository",
    info: "Upload any codebase and files such as document, powerpoints, and internal business processes.",
    image: null,
  },
  {
    title: "Code inspector",
    info: "Summarize, inspect, and standardize code across different platforms and interfaces.",
    image: null,
  },
  {
    title: "Collaborate with team members",
    info: "Sharing of learnings and best practices with your hardware team.",
    image: null,
  },
  {
    title: "Local deployment",
    info: "Maintain ultimate privacy of your data by running our software locally. ",
    image: null,
  },
];

const ProjectsSignup = () => {
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (email) {
      //   console.log(email);

      fetch(ApiAddress + "waitlist/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
        }),
      })
        .then((response) => {
          if (!response.ok && response.status === 401) {
            // Token is probably expired. Remove local data and redirect to login.
            throw new Error("Error joining the waitlist");
          }
          return response.json();
        })
        .then((data) => {
          // console.log(data);
          // console.log(deviceDetail);
          setEmail("");
        })
        .catch((error) => console.error("Error fetching device:", error));
    }
  };
  return (
    <div className="flex flex-col w-full h-screen overflow-auto bg-dark-primary text-white">
      <TopBar />
      <div className="w-4/5 mx-auto flex flex-col border border-0 mb-10">
        {/* Grid Layout */}
        <div className="flex flex-col w-full justify-center">
          <h1 className="flex justify-center font-bold text-xl mt-8 mb-8">
            A private workspace where users can collaborate on hardware projects
            with their team
          </h1>
          <div className="flex justify-center items-center">
            {/* <p>To access this private workspace</p> */}
            {/* <label className="text-left mb-4" htmlFor="waitlistEmail">
            For access to projects, please join our waitlist:
          </label> */}
            <form onSubmit={handleSubmit} className="flex justify-center mb-4">
              <input
                type="email"
                id="waitlistEmail"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="text-sm mr-2 px-3 py-2 rounded text-dark-primary"
              />
              <button
                type="submit"
                className="flex justify-center text-sm flex items-center gap-2 px-3 py-2 text-white rounded bg-gradient-to-br from-[#54E1AE] to-[#15BE81] hover:from-[#4ad5a0] hover:to-[#12a973]"
              >
                Join the waitlist
                <FontAwesomeIcon icon={faArrowRight} />
              </button>
            </form>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-10 mt-4">
          {info_card_contents.map((card, idx) => (
            <CardComponent key={idx} {...card} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProjectsSignup;
